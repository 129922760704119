import { MDXProvider } from "@mdx-js/react";
import PropTypes from "prop-types";
import React from "react";
import CodeBlock from "./CodeBlock";
import Footer from "./Footer";
import Navbar from "./Navbar";

const components = {
  pre: (props) => <div {...props} />,
  code: CodeBlock,
};

const Layout = ({ children }) => {
  return (
    <>
      <MDXProvider components={components}>
        <Navbar />
        <main>{children}</main>
        <Footer />
      </MDXProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
