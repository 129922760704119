import { Link } from "gatsby";
import React from "react";

const discordIcon = (
  <svg className="fill-current w-6 h-6" viewBox="0 0 640 512">
    <path d="M524.5 69.84a1.5 1.5 0 0 0 -.764-.7A485.1 485.1 0 0 0 404.1 32.03a1.816 1.816 0 0 0 -1.923 .91 337.5 337.5 0 0 0 -14.9 30.6 447.8 447.8 0 0 0 -134.4 0 309.5 309.5 0 0 0 -15.14-30.6 1.89 1.89 0 0 0 -1.924-.91A483.7 483.7 0 0 0 116.1 69.14a1.712 1.712 0 0 0 -.788 .676C39.07 183.7 18.19 294.7 28.43 404.4a2.016 2.016 0 0 0 .765 1.375A487.7 487.7 0 0 0 176 479.9a1.9 1.9 0 0 0 2.063-.676A348.2 348.2 0 0 0 208.1 430.4a1.86 1.86 0 0 0 -1.019-2.588 321.2 321.2 0 0 1 -45.87-21.85 1.885 1.885 0 0 1 -.185-3.126c3.082-2.309 6.166-4.711 9.109-7.137a1.819 1.819 0 0 1 1.9-.256c96.23 43.92 200.4 43.92 295.5 0a1.812 1.812 0 0 1 1.924 .233c2.944 2.426 6.027 4.851 9.132 7.16a1.884 1.884 0 0 1 -.162 3.126 301.4 301.4 0 0 1 -45.89 21.83 1.875 1.875 0 0 0 -1 2.611 391.1 391.1 0 0 0 30.01 48.81 1.864 1.864 0 0 0 2.063 .7A486 486 0 0 0 610.7 405.7a1.882 1.882 0 0 0 .765-1.352C623.7 277.6 590.9 167.5 524.5 69.84zM222.5 337.6c-28.97 0-52.84-26.59-52.84-59.24S193.1 219.1 222.5 219.1c29.67 0 53.31 26.82 52.84 59.24C275.3 310.1 251.9 337.6 222.5 337.6zm195.4 0c-28.97 0-52.84-26.59-52.84-59.24S388.4 219.1 417.9 219.1c29.67 0 53.31 26.82 52.84 59.24C470.7 310.1 447.5 337.6 417.9 337.6z"></path>
  </svg>
);

const twitterIcon = (
  <svg viewBox="0 0 19 15" className="fill-current w-6 h-6">
    <path d="M16.3024 3.19777L17.375 1.9649C17.6855 1.63099 17.7702 1.37414 17.7984 1.24572C16.9516 1.70805 16.1613 1.86216 15.6532 1.86216H15.4556L15.3427 1.75942C14.6653 1.22003 13.8185 0.9375 12.9153 0.9375C10.9395 0.9375 9.3871 2.42723 9.3871 4.14812C9.3871 4.25086 9.3871 4.40497 9.41532 4.50771L9.5 5.0214L8.90726 4.99572C5.29435 4.89298 2.33065 2.06764 1.85081 1.57962C1.06048 2.86387 1.5121 4.09675 1.99194 4.86729L2.95161 6.30565L1.42742 5.5351C1.45565 6.61387 1.90726 7.46147 2.78226 8.07791L3.54435 8.59161L2.78226 8.87415C3.2621 10.1841 4.33468 10.7235 5.125 10.9289L6.16935 11.1858L5.18145 11.8022C3.60081 12.8296 1.625 12.7526 0.75 12.6755C2.52823 13.8057 4.64516 14.0625 6.1129 14.0625C7.21371 14.0625 8.03226 13.9598 8.22984 13.8827C16.1331 12.1875 16.5 5.76627 16.5 4.48202V4.30223L16.6694 4.19949C17.629 3.37757 18.0242 2.94092 18.25 2.68408C18.1653 2.70976 18.0524 2.76113 17.9395 2.78682L16.3024 3.19777Z" />
  </svg>
);

const githubIcon = (
  <svg viewBox="0 0 32 32" className="fill-current w-7 h-7">
    <path d="M16 4.55078C9.625 4.55078 4.375 9.72578 4.375 16.1758C4.375 21.2758 7.7125 25.6258 12.3625 27.2008C12.9625 27.3133 13.15 26.9383 13.15 26.6758C13.15 26.4133 13.15 25.6633 13.1125 24.6508C9.8875 25.4008 9.2125 23.0758 9.2125 23.0758C8.6875 21.7633 7.9 21.3883 7.9 21.3883C6.85 20.6383 7.9375 20.6383 7.9375 20.6383C9.1 20.6758 9.7375 21.8383 9.7375 21.8383C10.75 23.6383 12.475 23.1133 13.1125 22.7758C13.225 22.0258 13.525 21.5008 13.8625 21.2008C11.3125 20.9383 8.575 19.9258 8.575 15.5008C8.575 14.2258 9.0625 13.2133 9.775 12.4258C9.6625 12.1633 9.25 10.9633 9.8875 9.35078C9.8875 9.35078 10.9 9.05078 13.1125 10.5508C14.05 10.2883 15.025 10.1383 16.0375 10.1383C17.05 10.1383 18.0625 10.2508 18.9625 10.5508C21.175 9.08828 22.15 9.35078 22.15 9.35078C22.7875 10.9258 22.4125 12.1633 22.2625 12.4258C23.0125 13.2133 23.4625 14.2633 23.4625 15.5008C23.4625 19.9258 20.725 20.9383 18.175 21.2008C18.5875 21.5758 18.9625 22.3258 18.9625 23.3758C18.9625 24.9508 18.925 26.1883 18.925 26.5633C18.925 26.8633 19.15 27.2008 19.7125 27.0883C24.2875 25.5508 27.625 21.2383 27.625 16.1008C27.5875 9.72578 22.375 4.55078 16 4.55078Z" />
  </svg>
);

const shape1 = (
  <svg
    width="217"
    height="189"
    viewBox="0 0 217 189"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      opacity="0.8"
      cx="76.5"
      cy="140.5"
      rx="140.5"
      ry="140.5"
      transform="rotate(180 76.5 140.5)"
      fill="url(#paint0_linear_965_2952)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_965_2952"
        x1="76.5"
        y1="-1.52588e-05"
        x2="76.5"
        y2="281"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0.22" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

const shape2 = (
  <svg
    width="96"
    height="237"
    viewBox="0 0 96 237"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.45"
      d="M202.292 34.7079C248.569 80.985 248.569 156.015 202.292 202.292C156.015 248.569 80.985 248.569 34.7079 202.292C-11.5693 156.015 -11.5693 80.985 34.7078 34.7078C80.985 -11.5693 156.015 -11.5693 202.292 34.7079Z"
      fill="url(#paint0_linear_965_2989)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_965_2989"
        x1="118.5"
        y1="0"
        x2="118.5"
        y2="237"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#6A64F1" />
        <stop offset="1" stop-color="#6A64F1" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

const shape3 = (
  <svg
    width="43"
    height="95"
    viewBox="0 0 43 95"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <circle
        cx="40.9982"
        cy="1.49626"
        r="1.49626"
        transform="rotate(90 40.9982 1.49626)"
        fill="white"
      />
      <circle
        cx="27.8303"
        cy="1.49626"
        r="1.49626"
        transform="rotate(90 27.8303 1.49626)"
        fill="white"
      />
      <circle
        cx="14.6643"
        cy="1.49626"
        r="1.49626"
        transform="rotate(90 14.6643 1.49626)"
        fill="white"
      />
      <circle
        cx="1.4963"
        cy="1.49626"
        r="1.49626"
        transform="rotate(90 1.4963 1.49626)"
        fill="white"
      />
      <circle
        cx="40.9982"
        cy="14.6642"
        r="1.49626"
        transform="rotate(90 40.9982 14.6642)"
        fill="white"
      />
      <circle
        cx="27.8303"
        cy="14.6642"
        r="1.49626"
        transform="rotate(90 27.8303 14.6642)"
        fill="white"
      />
      <circle
        cx="14.6643"
        cy="14.6642"
        r="1.49626"
        transform="rotate(90 14.6643 14.6642)"
        fill="white"
      />
      <circle
        cx="1.4963"
        cy="14.6642"
        r="1.49626"
        transform="rotate(90 1.4963 14.6642)"
        fill="white"
      />
      <circle
        cx="40.9982"
        cy="27.8302"
        r="1.49626"
        transform="rotate(90 40.9982 27.8302)"
        fill="white"
      />
      <circle
        cx="40.9982"
        cy="67.0314"
        r="1.49626"
        transform="rotate(90 40.9982 67.0314)"
        fill="white"
      />
      <circle
        cx="27.8303"
        cy="27.8302"
        r="1.49626"
        transform="rotate(90 27.8303 27.8302)"
        fill="white"
      />
      <circle
        cx="27.8303"
        cy="67.0334"
        r="1.49626"
        transform="rotate(90 27.8303 67.0334)"
        fill="white"
      />
      <circle
        cx="14.6643"
        cy="27.8302"
        r="1.49626"
        transform="rotate(90 14.6643 27.8302)"
        fill="white"
      />
      <circle
        cx="14.6643"
        cy="67.0334"
        r="1.49626"
        transform="rotate(90 14.6643 67.0334)"
        fill="white"
      />
      <circle
        cx="1.4963"
        cy="27.8302"
        r="1.49626"
        transform="rotate(90 1.4963 27.8302)"
        fill="white"
      />
      <circle
        cx="1.4963"
        cy="67.0314"
        r="1.49626"
        transform="rotate(90 1.4963 67.0314)"
        fill="white"
      />
      <circle
        cx="40.9982"
        cy="40.9982"
        r="1.49626"
        transform="rotate(90 40.9982 40.9982)"
        fill="white"
      />
      <circle
        cx="40.9982"
        cy="80.1994"
        r="1.49626"
        transform="rotate(90 40.9982 80.1994)"
        fill="white"
      />
      <circle
        cx="27.8303"
        cy="40.9982"
        r="1.49626"
        transform="rotate(90 27.8303 40.9982)"
        fill="white"
      />
      <circle
        cx="27.8303"
        cy="80.1994"
        r="1.49626"
        transform="rotate(90 27.8303 80.1994)"
        fill="white"
      />
      <circle
        cx="14.6643"
        cy="40.9982"
        r="1.49626"
        transform="rotate(90 14.6643 40.9982)"
        fill="white"
      />
      <circle
        cx="14.6643"
        cy="80.1994"
        r="1.49626"
        transform="rotate(90 14.6643 80.1994)"
        fill="white"
      />
      <circle
        cx="1.4963"
        cy="40.9982"
        r="1.49626"
        transform="rotate(90 1.4963 40.9982)"
        fill="white"
      />
      <circle
        cx="1.4963"
        cy="80.1994"
        r="1.49626"
        transform="rotate(90 1.4963 80.1994)"
        fill="white"
      />
      <circle
        cx="40.9982"
        cy="54.1642"
        r="1.49626"
        transform="rotate(90 40.9982 54.1642)"
        fill="white"
      />
      <circle
        cx="40.9982"
        cy="93.3674"
        r="1.49626"
        transform="rotate(90 40.9982 93.3674)"
        fill="white"
      />
      <circle
        cx="27.8303"
        cy="54.1642"
        r="1.49626"
        transform="rotate(90 27.8303 54.1642)"
        fill="white"
      />
      <circle
        cx="27.8303"
        cy="93.3674"
        r="1.49626"
        transform="rotate(90 27.8303 93.3674)"
        fill="white"
      />
      <circle
        cx="14.6643"
        cy="54.1642"
        r="1.49626"
        transform="rotate(90 14.6643 54.1642)"
        fill="white"
      />
      <circle
        cx="14.6643"
        cy="93.3674"
        r="1.49626"
        transform="rotate(90 14.6643 93.3674)"
        fill="white"
      />
      <circle
        cx="1.4963"
        cy="54.1642"
        r="1.49626"
        transform="rotate(90 1.4963 54.1642)"
        fill="white"
      />
      <circle
        cx="1.4963"
        cy="93.3674"
        r="1.49626"
        transform="rotate(90 1.4963 93.3674)"
        fill="white"
      />
    </g>
  </svg>
);

export default function Footer() {
  return (
    <footer className="bg-black">
      <div className="pt-20 md:pt-24 relative z-10">
        <div className="container  md:pb-12">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full sm:w-1/2 lg:w-1/4 px-4">
              <div className="mb-12">
                <h2 className="font-semibold text-xl text-white mb-10">
                  Important Links
                </h2>
                <ul className="space-y-3">
                  <li>
                    <Link
                      to="#"
                      className="font-medium text-base text-white hover:text-primary"
                    >
                      Our Pricing Plans
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="font-medium text-base text-white hover:text-primary"
                    >
                      About Our Company
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="font-medium text-base text-white hover:text-primary"
                    >
                      Our Latest Blogs
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="font-medium text-base text-white hover:text-primary"
                    >
                      Status
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/4 px-4">
              <div className="mb-12">
                <h2 className="font-semibold text-xl text-white mb-10">
                  Features
                </h2>
                <ul className="space-y-3">
                  <li>
                    <Link
                      to="#"
                      className="font-medium text-base text-white hover:text-primary"
                    >
                      File Upload Form
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="font-medium text-base text-white hover:text-primary"
                    >
                      Autoresponse
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="font-medium text-base text-white hover:text-primary"
                    >
                      Webhooks
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="font-medium text-base text-white hover:text-primary"
                    >
                      E-mail Notification
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/4 px-4">
              <div className="mb-12">
                <h2 className="font-semibold text-xl text-white mb-10">
                  Supports
                </h2>
                <ul className="space-y-3">
                  <li>
                    <Link
                      to="#"
                      className="font-medium text-base text-white hover:text-primary"
                    >
                      Supports
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="font-medium text-base text-white hover:text-primary"
                    >
                      Documentation
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="font-medium text-base text-white hover:text-primary"
                    >
                      Faqs
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="font-medium text-base text-white hover:text-primary"
                    >
                      Support Ticket
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/4 px-4">
              <div className="mb-12">
                <h2 className="font-semibold text-xl text-white mb-10">
                  Terms & Conditions
                </h2>
                <ul className="space-y-3">
                  <li>
                    <Link
                      to="/terms"
                      className="font-medium text-base text-white hover:text-primary"
                    >
                      Terms And Conditions
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/privacy-policy"
                      className="font-medium text-base text-white hover:text-primary"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="font-medium text-base text-white hover:text-primary"
                    >
                      Refund Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="font-medium text-base text-white hover:text-primary"
                    >
                      Security
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <span className="absolute -z-10 left-0 bottom-0">{shape1}</span>
        <span className="absolute -z-10 right-0 top-5">{shape2}</span>
        <span className="absolute -z-10 right-5 top-5">{shape3}</span>
      </div>

      <div className="border-t border-white border-opacity-30 py-5 lg:py-9">
        <div className="container">
          <div className="flex flex-wrap items-center -mx-4">
            <div className="w-full md:w-1/2 px-4">
              <p className="font-medium text-base text-white text-center md:text-left mb-4 md:mb-0">
                &copy; Copyright 2021-2022 | Formbold
              </p>
            </div>
            <div className="w-full md:w-1/2 px-4">
              <div className="flex items-center justify-center md:justify-end">
                <span className="text-white font-medium text-base">
                  Connect with us on:
                </span>
                <a
                  href="https://discord.gg/AgMAnZDafr"
                  rel="noreferrer noopener"
                  name="social link"
                  aria-label="social link"
                  className="mx-2 sm:mr-0 sm:ml-4 text-white hover:text-primary transition"
                >
                  {discordIcon}
                </a>
                <a
                  href="https://twitter.com/formbold/"
                  rel="noreferrer noopener"
                  name="social link"
                  aria-label="social link"
                  className="mx-2 sm:mr-0 sm:ml-4 text-white hover:text-primary transition"
                >
                  {twitterIcon}
                </a>
                <a
                  href="https://github.com/formbold"
                  rel="noreferrer noopener"
                  name="social link"
                  aria-label="social link"
                  className="mx-2 sm:mr-0 sm:ml-4 text-white hover:text-primary transition"
                >
                  {githubIcon}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
