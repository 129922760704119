import { Link } from "gatsby";
import React, { useState } from "react";
import Headroom from "react-headroom";

const logoImage = (
  <svg
    width="150"
    height="28"
    viewBox="0 0 150 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 0V28H10.8031V22.3096H22.6864V16.7699H10.8031V11.3055H22.6864V5.84118H5.38896V28H0V0H28Z"
      fill="#6A64F1"
    />
    <path
      d="M41 23.5V5.3H51.751V8.472H44.172V12.164H50.503V15.336H44.172V23.5H41Z"
      fill="#07074D"
    />
    <path
      d="M58.3235 23.838C57.1275 23.838 56.0355 23.5303 55.0475 22.915C54.0682 22.2997 53.2838 21.4763 52.6945 20.445C52.1138 19.405 51.8235 18.2567 51.8235 17C51.8235 16.0467 51.9925 15.1583 52.3305 14.335C52.6685 13.503 53.1322 12.775 53.7215 12.151C54.3195 11.5183 55.0128 11.0243 55.8015 10.669C56.5902 10.3137 57.4308 10.136 58.3235 10.136C59.5195 10.136 60.6072 10.4437 61.5865 11.059C62.5745 11.6743 63.3589 12.502 63.9395 13.542C64.5288 14.582 64.8235 15.7347 64.8235 17C64.8235 17.9447 64.6545 18.8287 64.3165 19.652C63.9785 20.4753 63.5105 21.2033 62.9125 21.836C62.3232 22.46 61.6342 22.9497 60.8455 23.305C60.0655 23.6603 59.2248 23.838 58.3235 23.838ZM58.3235 20.666C58.9562 20.666 59.5238 20.497 60.0265 20.159C60.5292 19.821 60.9235 19.3747 61.2095 18.82C61.5042 18.2653 61.6515 17.6587 61.6515 17C61.6515 16.324 61.4998 15.7087 61.1965 15.154C60.9018 14.5907 60.4988 14.1443 59.9875 13.815C59.4848 13.477 58.9302 13.308 58.3235 13.308C57.6995 13.308 57.1362 13.477 56.6335 13.815C56.1308 14.153 55.7322 14.6037 55.4375 15.167C55.1428 15.7303 54.9955 16.3413 54.9955 17C54.9955 17.6847 55.1472 18.3043 55.4505 18.859C55.7538 19.4137 56.1568 19.8557 56.6595 20.185C57.1708 20.5057 57.7255 20.666 58.3235 20.666Z"
      fill="#07074D"
    />
    <path
      d="M74.7517 13.724C74.5177 13.568 74.262 13.4423 73.9847 13.347C73.716 13.2517 73.417 13.204 73.0877 13.204C72.455 13.204 71.909 13.3687 71.4497 13.698C70.999 14.0273 70.6523 14.4693 70.4097 15.024C70.167 15.57 70.0457 16.1767 70.0457 16.844V23.5H66.8477V10.5H70.0197V12.034C70.453 11.462 70.9687 11.007 71.5667 10.669C72.1733 10.331 72.858 10.162 73.6207 10.162C73.9153 10.162 74.197 10.1707 74.4657 10.188C74.743 10.2053 75.0073 10.253 75.2587 10.331L74.7517 13.724Z"
      fill="#07074D"
    />
    <path
      d="M76.752 23.5V10.5H79.924V11.813C80.3054 11.3883 80.7084 11.046 81.133 10.786C81.5664 10.5173 82.056 10.383 82.602 10.383C83.486 10.383 84.292 10.5693 85.02 10.942C85.7567 11.306 86.3894 11.826 86.918 12.502C87.4467 11.826 88.0794 11.306 88.816 10.942C89.5527 10.5693 90.3587 10.383 91.234 10.383C92.352 10.383 93.3487 10.6603 94.224 11.215C95.108 11.7697 95.8057 12.515 96.317 13.451C96.8284 14.3783 97.084 15.4053 97.084 16.532V23.5H93.912V16.532C93.912 15.9773 93.7994 15.479 93.574 15.037C93.3487 14.5863 93.0324 14.2267 92.625 13.958C92.2264 13.6893 91.7627 13.555 91.234 13.555C90.7054 13.555 90.233 13.6893 89.817 13.958C89.4097 14.2267 89.089 14.5863 88.855 15.037C88.621 15.479 88.504 15.9773 88.504 16.532V23.5H85.332V16.532C85.332 15.9773 85.2107 15.479 84.968 15.037C84.734 14.5863 84.409 14.2267 83.993 13.958C83.5857 13.6893 83.122 13.555 82.602 13.555C82.0994 13.555 81.6444 13.6937 81.237 13.971C80.8297 14.2483 80.509 14.6167 80.275 15.076C80.041 15.5353 79.924 16.038 79.924 16.584V23.5H76.752Z"
      fill="#07074D"
    />
    <path
      d="M100.729 23.5V5.3H107.359C108.321 5.3 109.187 5.49933 109.959 5.898C110.739 6.288 111.354 6.85133 111.805 7.588C112.264 8.316 112.494 9.17833 112.494 10.175C112.494 10.617 112.429 11.0633 112.299 11.514C112.169 11.9647 111.969 12.372 111.701 12.736C111.432 13.0913 111.085 13.3557 110.661 13.529C111.224 13.6677 111.683 13.919 112.039 14.283C112.394 14.647 112.671 15.076 112.871 15.57C113.07 16.0553 113.204 16.5537 113.274 17.065C113.352 17.5677 113.391 18.0357 113.391 18.469C113.391 19.431 113.152 20.289 112.676 21.043C112.199 21.797 111.558 22.395 110.752 22.837C109.954 23.279 109.07 23.5 108.1 23.5H100.729ZM103.901 20.783H107.775C108.295 20.783 108.763 20.6573 109.179 20.406C109.595 20.1547 109.928 19.808 110.18 19.366C110.431 18.924 110.557 18.4213 110.557 17.858C110.557 17.3727 110.431 16.9133 110.18 16.48C109.928 16.0467 109.595 15.6957 109.179 15.427C108.763 15.1583 108.295 15.024 107.775 15.024H103.901V20.783ZM103.901 12.346H106.995C107.636 12.346 108.182 12.1467 108.633 11.748C109.092 11.3493 109.322 10.8207 109.322 10.162C109.322 9.46 109.092 8.93133 108.633 8.576C108.182 8.212 107.636 8.03 106.995 8.03H103.901V12.346Z"
      fill="#07074D"
    />
    <path
      d="M121.708 23.838C120.512 23.838 119.42 23.5303 118.432 22.915C117.453 22.2997 116.669 21.4763 116.079 20.445C115.499 19.405 115.208 18.2567 115.208 17C115.208 16.0467 115.377 15.1583 115.715 14.335C116.053 13.503 116.517 12.775 117.106 12.151C117.704 11.5183 118.398 11.0243 119.186 10.669C119.975 10.3137 120.816 10.136 121.708 10.136C122.904 10.136 123.992 10.4437 124.971 11.059C125.959 11.6743 126.744 12.502 127.324 13.542C127.914 14.582 128.208 15.7347 128.208 17C128.208 17.9447 128.039 18.8287 127.701 19.652C127.363 20.4753 126.895 21.2033 126.297 21.836C125.708 22.46 125.019 22.9497 124.23 23.305C123.45 23.6603 122.61 23.838 121.708 23.838ZM121.708 20.666C122.341 20.666 122.909 20.497 123.411 20.159C123.914 19.821 124.308 19.3747 124.594 18.82C124.889 18.2653 125.036 17.6587 125.036 17C125.036 16.324 124.885 15.7087 124.581 15.154C124.287 14.5907 123.884 14.1443 123.372 13.815C122.87 13.477 122.315 13.308 121.708 13.308C121.084 13.308 120.521 13.477 120.018 13.815C119.516 14.153 119.117 14.6037 118.822 15.167C118.528 15.7303 118.38 16.3413 118.38 17C118.38 17.6847 118.532 18.3043 118.835 18.859C119.139 19.4137 119.542 19.8557 120.044 20.185C120.556 20.5057 121.11 20.666 121.708 20.666Z"
      fill="#07074D"
    />
    <path d="M130.222 23.5V4H133.394V23.5H130.222Z" fill="#07074D" />
    <path
      d="M146.433 4H149.605V23.5H146.433V21.875C146.017 22.4557 145.493 22.928 144.86 23.292C144.228 23.656 143.495 23.838 142.663 23.838C141.719 23.838 140.835 23.6603 140.011 23.305C139.188 22.9497 138.46 22.46 137.827 21.836C137.203 21.2033 136.714 20.4753 136.358 19.652C136.003 18.8287 135.825 17.9447 135.825 17C135.825 16.0553 136.003 15.1713 136.358 14.348C136.714 13.5247 137.203 12.801 137.827 12.177C138.46 11.5443 139.188 11.0503 140.011 10.695C140.835 10.3397 141.719 10.162 142.663 10.162C143.495 10.162 144.228 10.344 144.86 10.708C145.493 11.0633 146.017 11.5357 146.433 12.125V4ZM142.702 20.783C143.378 20.783 143.976 20.614 144.496 20.276C145.016 19.938 145.424 19.483 145.718 18.911C146.013 18.3303 146.16 17.6933 146.16 17C146.16 16.298 146.013 15.661 145.718 15.089C145.424 14.5083 145.012 14.049 144.483 13.711C143.963 13.373 143.37 13.204 142.702 13.204C142.035 13.204 141.428 13.3773 140.882 13.724C140.336 14.062 139.899 14.517 139.569 15.089C139.24 15.661 139.075 16.298 139.075 17C139.075 17.702 139.24 18.339 139.569 18.911C139.899 19.483 140.336 19.938 140.882 20.276C141.437 20.614 142.044 20.783 142.702 20.783Z"
      fill="#07074D"
    />
  </svg>
);

const menu = [
  {
    title: "Features",
    path: "/#features",
  },

  {
    title: "Templates",
    path: "/templates",
  },

  {
    title: "Pricing",
    path: "/pricing",
  },
  {
    title: "Support",
    path: "/support",
  },
];

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuButtonHandler = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const [sticky, setSticky] = useState(false);

  return (
    <>
      <header className="absolute bg-transparent w-full z-40">
        <Headroom
          onPin={() => setSticky(true)}
          onUnpin={() => setSticky(false)}
          style={{
            padding: "15px 0",
            background: `${sticky ? "hsla(0, 0%, 100%, 0.8)" : ""}`,
            boxShadow: `${sticky ? "inset 0 -1px 0 0 rgba(0, 0, 0, 0.1)" : ""}`,
            backdropFilter: `${sticky ? "saturate(180%) blur(5px)" : ""}`,
          }}
        >
          <div className="container">
            <div className="flex flex-wrap -mx-4">
              <div className="w-full px-4">
                <div className="flex items-center justify-between relative">
                  <div className="max-w-[180px] w-full mr-5">
                    <Link to="/" name="formBold" aria-label="formBold">
                      {logoImage}
                    </Link>
                  </div>
                  <div className="flex items-center">
                    <button
                      className="bg-transparent absolute right-0 top-1/2 -translate-y-1/2 cursor-pointer block lg:hidden"
                      onClick={menuButtonHandler}
                      name="mobileMenuButton"
                      aria-label="mobileMenuButton"
                    >
                      <span
                        className={`w-8 h-[2px] bg-gray-900 block relative my-2 mx-auto transition ${
                          isMenuOpen ? "rotate-45" : " "
                        }`}
                      ></span>
                      <span
                        className={`w-8 h-[2px] bg-gray-900 block relative my-2 mx-auto transition ${
                          isMenuOpen ? "hidden" : ""
                        }`}
                      ></span>
                      <span
                        className={`w-8 h-[2px] bg-gray-900 block relative my-2 mx-auto transition ${
                          isMenuOpen ? "-rotate-45 top-[-10px]" : ""
                        }`}
                      ></span>
                    </button>
                    <nav
                      className={`absolute right-0 top-full py-2 shadow-md shadow-purple-100 lg:shadow-none lg:py-0 mt-4 lg:mt-0 max-w-[250px] w-full rounded bg-white z-40 lg:static lg:max-w-full lg:bg-transparent ${
                        isMenuOpen ? "block" : "hidden"
                      } lg:block `}
                    >
                      <ul className="lg:flex items-center justify-center">
                        {menu.map((item, index) => (
                          <li key={index}>
                            <Link
                              to={item.path}
                              onClick={menuButtonHandler}
                              activeClassName="text-primary"
                              className="flex items-center py-2 px-5 font-medium text-lg hover:text-primary"
                            >
                              {item.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>

                  <div className="hidden items-center sm:flex pr-[60px] lg:pr-0">
                    <div className="hidden xl:block">
                      <ul className="flex items-center justify-center sm:justify-start lg:justify-center ">
                        <li className="mx-2">
                          <a
                            href="https://discord.gg/AgMAnZDafr"
                            target="_blank"
                            rel="nofollow noreferrer noopener"
                            name="discord"
                            aria-label="discord"
                            className="text-body-color-2 hover:text-primary"
                          >
                            <svg
                              className="fill-current w-5 h-5"
                              viewBox="0 0 640 512"
                            >
                              <path d="M524.5 69.84a1.5 1.5 0 0 0 -.764-.7A485.1 485.1 0 0 0 404.1 32.03a1.816 1.816 0 0 0 -1.923 .91 337.5 337.5 0 0 0 -14.9 30.6 447.8 447.8 0 0 0 -134.4 0 309.5 309.5 0 0 0 -15.14-30.6 1.89 1.89 0 0 0 -1.924-.91A483.7 483.7 0 0 0 116.1 69.14a1.712 1.712 0 0 0 -.788 .676C39.07 183.7 18.19 294.7 28.43 404.4a2.016 2.016 0 0 0 .765 1.375A487.7 487.7 0 0 0 176 479.9a1.9 1.9 0 0 0 2.063-.676A348.2 348.2 0 0 0 208.1 430.4a1.86 1.86 0 0 0 -1.019-2.588 321.2 321.2 0 0 1 -45.87-21.85 1.885 1.885 0 0 1 -.185-3.126c3.082-2.309 6.166-4.711 9.109-7.137a1.819 1.819 0 0 1 1.9-.256c96.23 43.92 200.4 43.92 295.5 0a1.812 1.812 0 0 1 1.924 .233c2.944 2.426 6.027 4.851 9.132 7.16a1.884 1.884 0 0 1 -.162 3.126 301.4 301.4 0 0 1 -45.89 21.83 1.875 1.875 0 0 0 -1 2.611 391.1 391.1 0 0 0 30.01 48.81 1.864 1.864 0 0 0 2.063 .7A486 486 0 0 0 610.7 405.7a1.882 1.882 0 0 0 .765-1.352C623.7 277.6 590.9 167.5 524.5 69.84zM222.5 337.6c-28.97 0-52.84-26.59-52.84-59.24S193.1 219.1 222.5 219.1c29.67 0 53.31 26.82 52.84 59.24C275.3 310.1 251.9 337.6 222.5 337.6zm195.4 0c-28.97 0-52.84-26.59-52.84-59.24S388.4 219.1 417.9 219.1c29.67 0 53.31 26.82 52.84 59.24C470.7 310.1 447.5 337.6 417.9 337.6z"></path>
                            </svg>
                          </a>
                        </li>
                        <li className="mx-2">
                          <a
                            href="https://twitter.com/formbold/"
                            target="_blank"
                            rel="nofollow  noreferrer noopener"
                            name="twitter"
                            aria-label="twitter"
                            className="text-body-color-2 hover:text-primary"
                          >
                            <svg
                              viewBox="0 0 19 15"
                              className="fill-current w-5 h-5"
                            >
                              <path d="M16.3024 3.19777L17.375 1.9649C17.6855 1.63099 17.7702 1.37414 17.7984 1.24572C16.9516 1.70805 16.1613 1.86216 15.6532 1.86216H15.4556L15.3427 1.75942C14.6653 1.22003 13.8185 0.9375 12.9153 0.9375C10.9395 0.9375 9.3871 2.42723 9.3871 4.14812C9.3871 4.25086 9.3871 4.40497 9.41532 4.50771L9.5 5.0214L8.90726 4.99572C5.29435 4.89298 2.33065 2.06764 1.85081 1.57962C1.06048 2.86387 1.5121 4.09675 1.99194 4.86729L2.95161 6.30565L1.42742 5.5351C1.45565 6.61387 1.90726 7.46147 2.78226 8.07791L3.54435 8.59161L2.78226 8.87415C3.2621 10.1841 4.33468 10.7235 5.125 10.9289L6.16935 11.1858L5.18145 11.8022C3.60081 12.8296 1.625 12.7526 0.75 12.6755C2.52823 13.8057 4.64516 14.0625 6.1129 14.0625C7.21371 14.0625 8.03226 13.9598 8.22984 13.8827C16.1331 12.1875 16.5 5.76627 16.5 4.48202V4.30223L16.6694 4.19949C17.629 3.37757 18.0242 2.94092 18.25 2.68408C18.1653 2.70976 18.0524 2.76113 17.9395 2.78682L16.3024 3.19777Z" />
                            </svg>
                          </a>
                        </li>
                        <li className="mx-2">
                          <a
                            href="https://github.com/formbold"
                            target="_blank"
                            rel="nofollow noreferrer noopener"
                            name="github"
                            aria-label="github"
                            className="text-body-color-2 hover:text-primary"
                          >
                            <svg
                              viewBox="0 0 32 32"
                              className="fill-current w-6 h-6"
                            >
                              <path d="M16 4.55078C9.625 4.55078 4.375 9.72578 4.375 16.1758C4.375 21.2758 7.7125 25.6258 12.3625 27.2008C12.9625 27.3133 13.15 26.9383 13.15 26.6758C13.15 26.4133 13.15 25.6633 13.1125 24.6508C9.8875 25.4008 9.2125 23.0758 9.2125 23.0758C8.6875 21.7633 7.9 21.3883 7.9 21.3883C6.85 20.6383 7.9375 20.6383 7.9375 20.6383C9.1 20.6758 9.7375 21.8383 9.7375 21.8383C10.75 23.6383 12.475 23.1133 13.1125 22.7758C13.225 22.0258 13.525 21.5008 13.8625 21.2008C11.3125 20.9383 8.575 19.9258 8.575 15.5008C8.575 14.2258 9.0625 13.2133 9.775 12.4258C9.6625 12.1633 9.25 10.9633 9.8875 9.35078C9.8875 9.35078 10.9 9.05078 13.1125 10.5508C14.05 10.2883 15.025 10.1383 16.0375 10.1383C17.05 10.1383 18.0625 10.2508 18.9625 10.5508C21.175 9.08828 22.15 9.35078 22.15 9.35078C22.7875 10.9258 22.4125 12.1633 22.2625 12.4258C23.0125 13.2133 23.4625 14.2633 23.4625 15.5008C23.4625 19.9258 20.725 20.9383 18.175 21.2008C18.5875 21.5758 18.9625 22.3258 18.9625 23.3758C18.9625 24.9508 18.925 26.1883 18.925 26.5633C18.925 26.8633 19.15 27.2008 19.7125 27.0883C24.2875 25.5508 27.625 21.2383 27.625 16.1008C27.5875 9.72578 22.375 4.55078 16 4.55078Z" />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <ul className="flex items-center justify-end">
                      <li>
                        <a
                          href="https://app.formbold.com/login"
                          target="_blank"
                          rel="nofollow noreferrer noopener"
                          name="Login"
                          aria-label="Login"
                          className="inline-flex items-center justify-center text-base font-semibold py-3 px-6 text-body-color hover:text-primary"
                        >
                          Login
                        </a>
                      </li>
                      <li>
                        <Link
                          to="https://app.formbold.com/register"
                          name="get-started"
                          aria-label="get-started"
                          className="inline-flex items-center justify-center text-base font-semibold py-3 px-6 text-white bg-primary border-2 border-transparent rounded-md hover:bg-opacity-90"
                        >
                          Get Started
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Headroom>
      </header>
    </>
  );
}
